import styled from "styled-components";
import LandingPage from "./pages/LandingPage";
import ReactGA from "react-ga";

const TRACKING_ID = "UA-256835411-1";
ReactGA.initialize(TRACKING_ID);

ReactGA.pageview(window.location.pathname);

const Holder = styled.div`
  display: flex;
  flex-direction: column;
`;

const App = () => {
  return (
    <Holder>
      <LandingPage />
    </Holder>
  );
};

export default App;
