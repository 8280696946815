import styled from "styled-components";
import SectionText from "../text/SectionText";
import TextSection from "../text/TextSection";

const Holder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1920px;

  @media (max-width: 1500px) {
    align-items: center;
    width: 100%;
  }
`;

const Image = styled.img`
  width: 600px;

  @media (max-width: 1500px) {
    display: none;
  }
`;

const TextHolder = styled.div`
  width: 600px;
  @media (max-width: 1500px) {
    max-width: 1920px;
  }
`;

const Presentation = () => {
  return (
    <Holder>
      <TextHolder>
        <TextSection title="Welcome to the Model Citizen Project">
          <SectionText text="It's now been almost 10 years since we saw the release of GTA V, and although Rockstar Games has kept adding new features to GTA Online, the community has long been developing their own resources and servers to keep the game fresh." />
          <SectionText text="It's clear that a good part of the player-base enjoy the RP scene, and one of the reasons why GTA RP is still huge is because the players themselves create their own story rather than following the one set by Rockstar." />
          <SectionText text="This project aims to bridge the gap between RP and game content, facilitating all the role-playing players want, while also providing tons of content for the players to enjoy." />
        </TextSection>
      </TextHolder>
      <Image src="./media/images/aim_car.png" alt="man sitting in a car hood" />
    </Holder>
  );
};

export default Presentation;
