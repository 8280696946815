import styled from "styled-components";

const Header = styled.header`
  position: relative;
  width: 100vw;
  height: 500px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1500px) {
    height: 300px;
  }
`;

const BackgroundHolder = styled.div`
  position: absolute;
  inset: 0;
  z-index: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Background = styled.img`
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
`;

const Overlay = styled.img`
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  height: 50px;
  width: 100%;
`;

const TextHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;

const Title = styled.h1`
  fount-size: 35px;
  margin: 0;

  @media (max-width: 1500px) {
    font-size: 25px;
  }
`;
const Subtitle = styled.h2`
  font-size: 18px;
  font-weight: 400;
  margin: 0;

  @media (max-width: 1500px) {
    font-size: 13px;
  }
`;

const Banner = () => {
  return (
    <Header>
      <TextHolder>
        <Title> MODEL CITIZEN PROJECT</Title>
        <Subtitle>A new way to play</Subtitle>
      </TextHolder>
      <BackgroundHolder>
        <Background src="./media/banners/main/main.png" />
        <Overlay src="./media/banners/main/overlay.png" />
      </BackgroundHolder>
    </Header>
  );
};

export default Banner;
