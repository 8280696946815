import { useState } from "react";
import styled from "styled-components";
import Colors from "../../style/colors";

const Container = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.backgroundDarkGray};
  box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.5);

  transition: 0.1s ease-in-out;

  overflow: hidden;

  &:hover {
    height: 250px;
    cursor: pointer;

    img {
      opacity: 30%;
    }
  }
`;

const Image = styled.img`
  opacity: 70%;
`;

const Title = styled.h2<{ hovering: boolean }>`
  text-align: center;
  margin-bottom: 10px;

  ${({ hovering }) =>
    hovering
      ? ""
      : `
  background-color: ${Colors.backgroundDarkGray};
  border-radius: 15px;
  padding: 5px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`}
`;

const Text = styled.p`
  text-align: center;
`;

const Holder = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
`;

const Texts = styled.div<{ hovering: boolean }>`
  margin: 0 200px;

  ${({ hovering }) =>
    hovering
      ? `
  background-color: ${Colors.backgroundDarkGray};
  border-radius: 15px;
  padding: 5px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`
      : ""}
`;

const Footer = () => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <Container
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Image src="./media/banners/Footer/footer.png" alt="background" />
      <Holder>
        <Texts hovering={isHovering}>
          <Title hovering={isHovering}>This is an abandoned project</Title>
          {isHovering && (
            <>
              <Text>
                Unfortunately, this project has been abandoned for the time
                beeing. I'm sad to announce this, but for time management
                reasons it's the best decision that I can make at the moment.
                Feel free to join the discord server, even if it's kind of dead
                at the moment. If enough people become interested I'll reboot
                the project.
              </Text>
              <Text>
                <span style={{ color: "#d3d512" }}> WARNING: </span>The patreon
                link is up, but I don't guarantee that if you donate the project
                is going to come through.
              </Text>
            </>
          )}
        </Texts>
      </Holder>
    </Container>
  );
};

export default Footer;
