import styled from "styled-components";

interface Props {
  bgColor: string;
  borderColor: string;
  name: string;
  description: string;
  selected: boolean;
  featureId: number;
  onClick: (featureId: number) => void;
}

interface FeatureHolderProps {
  bgColor: string;
  borderColor: string;
  selected: boolean;
}

const FeatureHolder = styled.div`
  width: 250px;
  height: 175px;
  background-color: ${({ bgColor }: FeatureHolderProps) => bgColor};
  border: 5px solid ${({ borderColor }: FeatureHolderProps) => borderColor};
  opacity: ${({ selected }: FeatureHolderProps) => (selected ? "1" : "0.5")};
  border-radius: 15px;

  transition: all 0.4s;

  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  @media (max-width: 1500px) {
    width: 150px;
    height: 105px;
    padding: 10px;
  }
`;

const Name = styled.p`
  font-size: 17px;
  margin: 0;

  @media (max-width: 1500px) {
    font-size: 13px;
  }
`;

const Description = styled.p`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 14px;
  margin: 0;

  @media (max-width: 1500px) {
    font-size: 11px;
  }
`;

const Feature = ({
  bgColor,
  borderColor,
  name,
  description,
  selected,
  onClick,
  featureId,
}: Props) => {
  return (
    <FeatureHolder
      selected={selected}
      bgColor={bgColor}
      borderColor={borderColor}
      onClick={() => {
        onClick(featureId);
      }}
    >
      <Name>{name}</Name>
      <Description>{description}</Description>
    </FeatureHolder>
  );
};

export default Feature;
