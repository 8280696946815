import styled from "styled-components";
import Colors from "../style/colors";

interface Props {
  bgColor?: string;
  children: JSX.Element | JSX.Element[];
}

const Holder = styled.section`
  background-color: ${({ bgColor }: { bgColor?: string }) =>
    bgColor ?? Colors.lowOBG};

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 150px;

  @media (max-width: 1500px) {
    padding: 100px 50px;
  }
`;

const Section = ({ children, bgColor }: Props) => {
  return <Holder bgColor={bgColor}>{children}</Holder>;
};

export default Section;
