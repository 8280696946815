import { useEffect, useState } from "react";
import styled from "styled-components";
import SectionText from "../text/SectionText";
import SectionTitle from "../text/SectionTitle";
import TextSection from "../text/TextSection";
import Feature from "./Feature";

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  align-items: center;
  min-height: 500px;
  gap: 50px;
`;

const FeatureHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 100px;

  @media (max-width: 1500px) {
    flex-direction: column;
    gap: 50px;
    height: 500px;
  }

  @media (max-width: 1000px) {
    height: fit-content;
    height: 1050px;
  }
`;

const SelectorContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (max-width: 1500px) {
    height: fit-content;
    width: 100%;
  }
`;

const FeatureSelector = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 570px;
  gap: 70px;

  @media (max-width: 1500px) {
    gap: 10px;
    min-width: 350px;
    width: 700px;
  }

  @media (max-width: 1000px) {
    width: 300px;
  }
`;

const FeatureContent = styled.div`
  position: relative;
  flex: 1;
  height: 100%;

  @media (max-width: 1500px) {
    width: 100%;
  }
`;

const TextHolder = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0;
  opacity: ${({ show }: { show: boolean }) => (show ? "1" : "0")};
  transition: all 0.5s ease-in-out;
`;

const TextCenter = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1500px) {
    justify-content: flex-start;
  }
`;

const TimerHolder = styled.div`
  width: 100%;
  height: 10px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

const Timer = styled.div`
  height: 100%;
  // background-color: rgba(87, 208, 175, 0.5);
  background-color: rgba(255, 255, 255, 0.3);
`;

const timerStep = 10;
const timerDuration = 30000 / timerStep;

const Features = () => {
  const [currentFeature, setCurrentFeature] = useState<number>(0);
  const [timer, setTimer] = useState<number>(timerDuration);
  const [stopTimer, setStopTimer] = useState<boolean>(false);

  const onClick = (featureId: number) => {
    setStopTimer(true);
    setCurrentFeature(featureId);
  };

  useEffect(() => {
    const timerInstance = setInterval(() => {
      if (stopTimer) {
        return;
      }
      setTimer((timer) => timer - 1);
    }, timerStep);
    return () => clearInterval(timerInstance);
  }, [stopTimer]);

  useEffect(() => {
    if (timer === 0) {
      setCurrentFeature((currentFeature) =>
        currentFeature === 3 ? 0 : currentFeature + 1
      );
      setTimer(timerDuration);
    }
  }, [timer]);

  return (
    <Holder>
      <SectionTitle>Sneak Peak into some Features</SectionTitle>
      <FeatureHolder>
        <SelectorContainer>
          <FeatureSelector>
            <Feature
              featureId={0}
              selected={currentFeature === 0}
              onClick={onClick}
              bgColor={"rgba(7,133,189,0.8)"}
              borderColor={"rgba(10,162,230, 0.8)"}
              name={"Businesses"}
              description={"A player based business system"}
            />
            <Feature
              featureId={1}
              selected={currentFeature === 1}
              onClick={onClick}
              bgColor={"rgba(168,11,74, 0.8)"}
              borderColor={"rgba(207,10,89, 0.8)"}
              name={"NPC & Reputation"}
              description={"Access gameplay through NPC and reputation"}
            />
            <Feature
              featureId={2}
              selected={currentFeature === 2}
              onClick={onClick}
              bgColor={"rgba(161,185,11, 0.8)"}
              borderColor={"rgba(193,222,13, 0.8)"}
              name={"Skill tree"}
              description={"Discover and learn in-game skills"}
            />
            <Feature
              featureId={3}
              selected={currentFeature === 3}
              onClick={onClick}
              bgColor={"rgba(13,34,162, 0.8)"}
              borderColor={"rgba(17,42,206,0.8)"}
              name={"Police"}
              description={"Fight crime with incredible immersion"}
            />
          </FeatureSelector>
          <TimerHolder>
            <Timer style={{ width: `${(timer / timerDuration) * 100}%` }} />
          </TimerHolder>
        </SelectorContainer>
        <FeatureContent>
          <TextHolder show={currentFeature === 0}>
            <TextCenter>
              <TextSection title="Businesses">
                <SectionText text="Every business will be player owned and controlled. We'll have some essential businesses which will be entirely NPC based (in case there's no player employees of that business type available), but they will be very limited versions of what players can achieve doing the same job." />
                <SectionText text="Everyone will have the option to open their own business, any business imaginable is possible, but players will need to have sufficient funds to finance the first steps of the business and possess the right licenses, which sounds hard to obtain, but it's essential to maintain a business balance and to maintain a balanced the economy." />
                <SectionText text="We aim to make business interactive and fun to role-play, expanding on the idea of not making everyone a criminal in order to make money or enjoy themselves in the server." />
                <SectionText text="Every business will have tasks to do in order to keep the business up and running. Every business will have taxes / rent to pay, preventing abandoned businesses. (Excluding personal breaks)." />
                <SectionText text="Owners of a business can decide what tasks to delegate to other workers (players or NPCs depending on the task) or do themselves, keeping the gameplay varied and interactive, they will be able to earn passive income from any business, but that does mean they'll need to pay enough workers to complete all the tasks." />
              </TextSection>
            </TextCenter>
          </TextHolder>
          <TextHolder show={currentFeature === 1}>
            <TextCenter>
              <TextSection title="NPC & Reputation system">
                <SectionText text="We'll be introducing a reputation system. This will not be a binary good-bad system, but rather a more diverse implementation." />
                <SectionText text="Some of the gamplay features we'll be adding will be available through NPCs. You will have to talk to X NPC to have access to X place, X contact, tool..." />
                <SectionText text="NPCs will treat you differently based on your reputation, from straight not talking to you, to offering you all their services." />
                <SectionText text="Some NPCs will be easy to reach and talk to, while others will be much more difficult to have access to. Some will be throughout the map, other will require to set up meets..." />
                <SectionText text="Let's put an example, let's say you want to meet up with an NPC that offers something in the car thieving world, then it will require you to have certain reputation as a car thief, or at least some as a thieve overall." />
              </TextSection>
            </TextCenter>
          </TextHolder>
          <TextHolder show={currentFeature === 2}>
            <TextCenter>
              <TextSection title="Skill system">
                <SectionText text="We are also developing a skill system that will be based on a general skill tree." />
                <SectionText text="I'ts not yet clear wether the skill tree will be visible, or you as a player will have to discover the skills you can have on your own." />
                <SectionText text="Most of these skills will have to be learned, not simply obtained." />
                <SectionText text="Some skills will be learned by doing somthing X times untill you do it propperly, but some will be tought to you by an NPC, which of course you have to find on your own." />
              </TextSection>
            </TextCenter>
          </TextHolder>
          <TextHolder show={currentFeature === 3}>
            <TextCenter>
              <TextSection title="Police">
                <SectionText text="We want to create immersive police/militaty gameplay. From my personal experince, a lot of the police job in the majority of RP servers is based on traffic stops and vehicle chases, we don't want these to disappear, but we want to provide a lot more gamplay to the people that want to protect and enforce the law." />
                <SectionText text="Our aim with all of the features regarding police is to not only make it so police have more things to do, but also  to make criminal activites more difficult and hence rewarding to pull off." />
                <SectionText text="Some of the features we are looking into is bullet casing, facial recognition on CCTV, arrest records, fingerprint system..." />
                <SectionText text="We want detectives to be a thing in this server, making even full-on investigations possible, and on ocations, if players want to, trials." />
                <SectionText text="We do realise this seems a little bit far-fetched, but this system can work, as long as the playerbase is aware of it and wants to roleplay it." />
                <SectionText text="One thing we are debating is making the punishments harsh, including long prison sentences and fines, which sounds like somthing no one would like to go through, but is also an essential part of making criminal activities hard, keeping them with high reward, realistinc, and super exiting to pull off." />
              </TextSection>
            </TextCenter>
          </TextHolder>
        </FeatureContent>
      </FeatureHolder>
    </Holder>
  );
};

export default Features;
