import styled from "styled-components";

interface Props {
  title: string;
  children?: JSX.Element | JSX.Element[];
}

const Title = styled.h3`
  font-size: 25px;
  margin: 0;
  margin-bottom: 25px;

  @media (max-width: 1500px) {
    font-size: 15px;
    margin-bottom: 15px;
  }
`;

const TextHolder = styled.div``;

const TextSection = ({ title, children }: Props) => {
  return (
    <>
      <Title>{title}</Title>
      <TextHolder>{children}</TextHolder>
    </>
  );
};

export default TextSection;
