import styled from "styled-components";
import SectionText from "../text/SectionText";
import TextSection from "../text/TextSection";

const Holder = styled.div`
  display: flex;
  width: 100%;
  max-width: 1920px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1500px) {
    align-items: center;
    width: 100%;
  }
`;

const Image = styled.img`
  width: 600px;

  @media (max-width: 1500px) {
    display: none;
  }
`;

const TextHolder = styled.div`
  width: 600px;
  @media (max-width: 1500px) {
    max-width: 1920px;
  }
`;

const Gameplay = () => {
  return (
    <Holder>
      <Image src="./media/images/gameplay_posing.png" alt="a plane flying" />
      <TextHolder>
        <TextSection title="Gameplay Model">
          <SectionText text="The model that the server will follow is going to be completley player based. All the new gameplay features will be “options” for the player to use." />
          <SectionText text="We are not looking to implement mission-like content, rather all the supporting features for the players to create their own missions around." />
          <SectionText
            text="Let's take an example, a heist, this, being an RP server, will be difficult to achieve, and you will have a completely open approach to do it.
                  Imagine a GTAV Heist, but you are Lester, you scout places, figure out your own plan, contact the right people, execute it your own way..."
          />
          <SectionText text="We also want to provide a more balanced economy, where you don't always have to resort to crime in order to obtain money." />
        </TextSection>
      </TextHolder>
    </Holder>
  );
};

export default Gameplay;
