import styled from "styled-components";

interface Props {
  text: string | JSX.Element | JSX.Element[];
}

const Text = styled.p`
  margin-top: 0;

  a {
    color: #57d0af;

    &:hover {
      color: #ebfb77;
    }
  }

  @media (max-width: 1500px) {
    font-size: 13px;
  }
`;

const SectionText = ({ text }: Props) => {
  return <Text>{text}</Text>;
};

export default SectionText;
