import { useEffect, useState } from "react";
import styled from "styled-components";
import SectionText from "../text/SectionText";
import TextSection from "../text/TextSection";

const Holder = styled.div`
  display: flex;
  width: 100%;
  max-width: 1920px;
  justify-content: space-between;

  @media (max-width: 1500px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
  }
`;

const TextHolder = styled.div`
  max-width: 600px;

  @media (max-width: 1500px) {
    max-width: 1920px;
  }
`;

// const Copybutton = styled.button`
//   border: none;
//   background: none;
//   padding: 0;
//   cursor: pointer;

//   img {
//     height: 17px;
//     filter: invert(51%) sepia(11%) saturate(10%) hue-rotate(2deg)
//       brightness(97%) contrast(84%);

//     &:hover {
//       filter: invert(100%) sepia(0%) saturate(7486%) hue-rotate(184deg)
//         brightness(114%) contrast(98%);
//     }
//   }
// `;

const CopyPopup = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: white;
  color: black;

  border-radius: 15px;

  padding: 15px 25px;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: ${({ show }: { show: boolean }) => (show ? "1" : "0")};

  transition: all 0.5s;

  @media (max-width: 1500px) {
    top: 10px;
    right: 10px;
    padding: 10px 15px;
    font-size: 13px;
  }
`;

const RightHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 25px;
  gap: 30px;
`;

const SocialsHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

const SocialButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Social = styled.a`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  // border: none;
  // background: none;
  // padding: 0;
`;

const SocialImage = styled.img`
  height: 100%;
`;

const Contributions = () => {
  const [showCoppied, setShowCoppied] = useState<boolean>(false);

  useEffect(() => {
    if (showCoppied) {
      setTimeout(() => {
        setShowCoppied(false);
      }, 1500);
    }
  }, [showCoppied]);

  // const copy = (email: string) => {
  //   navigator.clipboard.writeText(email);
  //   setShowCoppied(true);
  // };

  return (
    <Holder>
      <CopyPopup show={showCoppied}> Coppied! </CopyPopup>
      <TextHolder>
        <TextSection title="We need your help!">
          <SectionText text="There's still soo much more to present to you, I wanted to keep this web/presentation light, and I already failed with just explaining the tip of the iceberg of what this project is. If you could consider reading this next part, it would really motivate me to work on this and bring it to light!" />
          <SectionText text="Building something this big  is expensive and time consuming. I myself, the owner and main developer, would like to work on this full time, but not only that, we also need funding to create all the custom assets for the server (3D modelers, voice actors...)." />
          <SectionText text="Keep in mind, this project will take time, and all the help to expedite it is appreciated it." />
          <SectionText
            text={
              <>
                Thank you so much for taking the time to explore this idea with
                us! We accept donations to the project through a{" "}
                <a
                  href="https://www.patreon.com/ModelCitizenProject"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Patreon subscription
                </a>{" "}
                or directly through our{" "}
                <a
                  href="https://www.paypal.com/donate/?hosted_button_id=TCVVV8T2S2GGN"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Paypal donation
                </a>
                .
              </>
            }
          />
          <SectionText
            text={
              <>
                If you think you can personally contribute to the project, we
                appreciate work contributions as well, contact us through
                discord with your proposition so we can work out a deal.
              </>
            }
          />
        </TextSection>
      </TextHolder>
      <RightHolder>
        <TextHolder>
          <TextSection title="For content creators">
            <SectionText text="We are also looking for content creators to partner up with to help spread the word about the project, both to reach out to as many players possible for when the release comes, but to also, admittedly, reach out to any potential supporters as well." />
            <SectionText
              text={
                <>
                  If you find this idea interesting and you are a content
                  creator, please contact me directly through discord DMs{" "}
                </>
              }
            />
            <SectionText text="We are hoping some of you would offer your reach as a content creator as your form of donation, but making a sponsor deal is not out of the equation." />
          </TextSection>
        </TextHolder>
        <SocialsHolder>
          <TextSection title="Join the community" />
          <SocialButtons>
            <Social
              href="https://www.patreon.com/ModelCitizenProject"
              target={"_blank"}
            >
              <SocialImage src="./icons/socials/patreon.png" alt="patreon" />
            </Social>
            <Social href="https://discord.gg/u4HUjWH6KU" target={"_blank"}>
              <SocialImage src="./icons/socials/discord.png" alt="discord" />
            </Social>
          </SocialButtons>
        </SocialsHolder>
      </RightHolder>
    </Holder>
  );
};

export default Contributions;
