import { useRef } from "react";
import styled from "styled-components";
import Banner from "../components/Banner";
import Contributions from "../components/landingPage/Contributions";
import Donations from "../components/landingPage/Donations";
import Features from "../components/landingPage/Features";
import Gameplay from "../components/landingPage/Gameplay";
import Presentation from "../components/landingPage/Presentation";
import Section from "../components/Section";
import Colors from "../style/colors";
import Footer from "../components/footer/Footer";

const Holder = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;

  background-image: url("./media/overlays/background_pattern.webp");
  background-size: 300px;
  margin-bottom: 100px;
`;

const Overlay = styled.img`
  position: absolute;
  top: 0;
  margin: 0 auto;
  transform: rotate(180deg);
  height: 30px;
  width: 100%;
`;

const Scrollable = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LandingPage = () => {
  const presentationRef = useRef<HTMLDivElement>(null);
  const gameplayRef = useRef<HTMLDivElement>(null);
  const featuresRef = useRef<HTMLDivElement>(null);
  const donationsRef = useRef<HTMLDivElement>(null);
  const contributionsRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Banner />
      <Holder>
        <Overlay src="./media/banners/main/overlay.png" />
        <Section>
          <Scrollable ref={presentationRef}>
            <Presentation />
          </Scrollable>
        </Section>
        <Section bgColor={Colors.lowOBDG}>
          <Scrollable ref={gameplayRef}>
            <Gameplay />
          </Scrollable>
        </Section>
        <Section>
          <Scrollable ref={featuresRef}>
            <Features />
          </Scrollable>
        </Section>
        <Section bgColor={Colors.lowOBDG}>
          <Scrollable ref={donationsRef}>
            <Donations />
          </Scrollable>
        </Section>
        <Section>
          <Scrollable ref={contributionsRef}>
            <Contributions />
          </Scrollable>
        </Section>
      </Holder>
      <Footer />
      {/* <NavigationBar
        sections={[
          {
            title: "Presentation",
            ref: presentationRef,
          },
          {
            title: "Gameplay",
            ref: gameplayRef,
          },
          {
            title: "Features",
            ref: featuresRef,
          },
          {
            title: "Donations",
            ref: donationsRef,
          },
          {
            title: "Contribute",
            ref: contributionsRef,
          },
        ]}
      /> */}
    </>
  );
};

export default LandingPage;
