import styled from "styled-components";
import SectionText from "../text/SectionText";
import TextSection from "../text/TextSection";

const Holder = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1920px;
`;

const TextHolder = styled.div``;

const Donations = () => {
  return (
    <Holder>
      <TextHolder>
        <TextSection title="Donation perks">
          <SectionText text="Donations to an RP server are a farly standard thing, we as developers need the donations, both to keep the servers up and running, and to make sure we have the time to keep working and updating them." />
          <SectionText text="Most of the servers that accept donations give an in-game reward to the player, usually a car not accesible through the game. We think that a donator still deserves an in-game reward, but we don't like that system. One of the reasons why is because it's a very pay-to-win oriented system." />
          <SectionText text="We are working on a token system we called “Custom Tokens” which will be an internal token system for players to redeem. Custom Tokens are earned by donating to the server (or other means), eliminating the donation car culture." />
          <SectionText text="We want to erradicate the pay-to-win system. Everything obtainable in the server is going to cost in-game money. Tokens are there to redeem for OOC work, like importing (and optimizing) a vehicle, house, cloathing item... into the server, or to build a custom interior, vehicle, clothing item... anything you can imagine. All this is going to cost more or less Custom Tokens based on how much OOC work is required, and the type of reward redeemed. They can also be redeemed to make something (that isn't already in circulation) completely unique (one-off)." />
          <SectionText text="An example: You donate to the server and get some Custom Tokens, then you decide that you want to spend your tokens importing a car to the server. The Develpment team will then optiomize and import that car, but your character won't receive it. You will still have to buy that car in-game and spend the in-game currency. This may sound unfair for the donor at first, but we'd rather have a fair experience to all players, even if you don't have the money to support the server." />
          <SectionText text="Remember that the donation money is really that, a donation to keep the server running, not an in-game purchase, and we want to treat it as such. Moreover, we think that the Custom Tokens system will still reward the donor massively, since they can just add the content that will make them the happiest." />
        </TextSection>
      </TextHolder>
    </Holder>
  );
};

export default Donations;
