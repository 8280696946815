enum Colors {
  darkBlue = "#09051E",
  backgroundGray = "#2f3442",
  backgroundDarkGray = "#2a2f3b",
  lowOBG = "rgba( 47, 52, 66, 0.9)",
  lowOBDG = "rgba( 42, 47, 59, 0.9)",
}

export enum FeatureColors {
  red = "red",
  blue = "blue",
  yellow = "yellow",
  green = "green",
}

export default Colors;
